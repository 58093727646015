import h from 'hyperscript';

import { createAnchorButton } from 'ui/button/button';

import { escapeAndMarkHTML } from 'cadenza/utils/escape-and-mark';

import i18n from './truncated-p.properties';
import './truncated-p.css';

const COMPONENT_NAME = 'd-truncated-p';
const ATTR_FULL_TEXT = 'full-text';
const ATTR_TRUNCATED_TEXT = 'truncated-text';

/**
 * A wrapper around the `<p>` element, that displays its text truncated and
 * can be expanded to the full text.
 *
 * There are two ways to use it:
 * 1. By default, the full text is truncated with ellipsis (…) after a certain number of lines
 *    (see `truncated-p.css`). When the text is truncated, a "More" button is shown to expand the
 *    full text.
 * 2. You might pass a shorter alternative text to show by default. In this case, a "More" / "Less"
 *    button toggles between the full text and the alternative text.
 *
 * Both, full and alternative text, might include limited formatting (see `mark()` function).
 *
 * _Note:_ The component can be initialized also via attributes.
 *
 * @property fullText - The text of the paragraph (linked to the `full-text` attribute)
 * @property truncatedText - A shorter alternative text (linked to the `truncated-text` attribute)
 */
export class TruncatedP extends HTMLElement {

  _p: HTMLElement = h(`p.${COMPONENT_NAME}--paragraph-container.d-stack-v`);
  _paragraphEllipsis = h(`p.${COMPONENT_NAME}--paragraph-ellipsis`, { attrs: { 'aria-hidden': 'true' } }, '…');
  _moreLessButton: HTMLElement = createAnchorButton('', {
    onclick: (event: Event) => {
      event.stopPropagation();
      this._collapsed = !this._collapsed;
    }
  });

  _resizeObserver = new ResizeObserver(() => this._onResize());

  connectedCallback () {
    this.classList.add(COMPONENT_NAME, 'd-stack-v', 'space-1');
    this.setAttribute('aria-live', 'polite');
    this.append(this._p, this._moreLessButton);
    this._render();
  }

  disconnectedCallback () {
    this._resizeObserver.disconnect();
  }

  _render () {
    const collapsed = this._collapsed;
    const truncatedText = this.truncatedText;
    const fullText = this.fullText;

    const pContainer = this._p;
    pContainer.innerHTML = escapeAndMarkHTML(!(collapsed && truncatedText) ? fullText : truncatedText);

    /*
     * Firefox does not support CSS line-clamp on multiple paragraphs, so hide sub-sequent paragraphs.
     *
     * Since ellipsis is a purely visual single thing (all text is still read by screen readers,
     * see https://butterpep.com/line-clamp-overflow-ellipsis.html), we hide the paragraphs only
     * visually.
     */
    [ ...pContainer.children ].forEach((p, i) => p.classList.toggle('visuallyhidden', i > 0 && collapsed));
    if (pContainer.children.length > 1) {
      pContainer.append(this._paragraphEllipsis);
    }

    this._moreLessButton.textContent = i18n(collapsed ? 'more' : 'less'); // reusing the buttons maintains the focus

    // For screen readers, the full text is visible also without expanding it.
    // So we show the more/less button only if there's a truncated text.
    this._moreLessButton.setAttribute('aria-hidden', String(!this.truncatedText));

    this._onResize();

    // We need the observer only for the ellipsis use case.
    if (!collapsed || truncatedText) {
      this._resizeObserver.unobserve(this);
    } else {
      this._resizeObserver.observe(this);
    }
  }

  _onResize () {
    const ps = this._p.children;
    const firstP = ps[0] as HTMLElement;
    // For whatever reason, the scrollHeight is 2px bigger even if there's no overflow.
    const firstPHasEllipsis = (firstP.offsetHeight < firstP.scrollHeight - 2);
    this._paragraphEllipsis.hidden = firstPHasEllipsis;
    this._moreLessButton.hidden = (ps.length === 1 && !firstPHasEllipsis && this._collapsed && !this.truncatedText);
  }

  set _collapsed (value: boolean) {
    // Not using the aria-expanded attribute, because that's for use with certain roles.
    this.classList.toggle('is-expanded', !value);
    this._render();
  }

  get _collapsed (): boolean {
    return !this.classList.contains('is-expanded');
  }

  set fullText (value: string) {
    this.setAttribute(ATTR_FULL_TEXT, value);
    this._render();
  }

  get fullText (): string {
    return this.getAttribute(ATTR_FULL_TEXT) || '';
  }

  set truncatedText (value: string | undefined) {
    if (value) {
      this.setAttribute(ATTR_TRUNCATED_TEXT, value);
    } else {
      this.removeAttribute(ATTR_TRUNCATED_TEXT);
    }
    this._render();
  }

  get truncatedText (): string | undefined {
    return this.getAttribute(ATTR_TRUNCATED_TEXT) || undefined;
  }

}

customElements.define(COMPONENT_NAME, TruncatedP);

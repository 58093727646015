import h from 'hyperscript';

import { getLogger } from 'cadenza/utils/logging';
import { defaultXhrErrorHandler } from 'cadenza/api-client/api';
import { HEADER_DISY_CANCEL_ID } from 'cadenza/api-client/api-constants';
import { on } from 'cadenza/utils/event-util';

const LOGGER = getLogger('cadenza/utils/download');

// INFO level (also in production), because blob downloads
// do not show up in the devtools' network tab.
LOGGER.setLevel(LOGGER.levels.INFO);

/**
 * Loads a file via XHR from the given URL and using the given options.
 * The file is downloaded entirely as a blob before the browser's download action is triggered,
 * in IE and family via the msSaveBlob function and in other browsers via an invisible anchor.
 *
 * Use this function if you need to...
 * - set request headers (e.g. Accept) or change the request method (e.g. to HEAD).
 * - know when the download is finished or whether it was successful.
 * - specify the download filename.
 *
 * Otherwise use {@link downloadImmediately}.
 *
 * Example usage:
 *
 * download(url);
 * download(url, {
 *   method: 'GET',
 *   headers: {
 *     'Accept': 'application/pdf', // What we'd like to get.
 *     'Authorization': 'Your auth key'
 *   },
 *   responseType: 'blob',
 *   filename: 'Over'
 * });
 *
 * @param {string} url - The URL of the file to download
 * @param {object} options - Options for the XHR request
 * @return {promise} a Promise that resolves when the files is downloaded
 */
export function download (url, options = {}) {
  // Allow options override
  options = Object.assign({
    method: 'GET',
    headers: {},
    responseType: 'blob',
    filename: undefined,
    defaultFilename: url.split('/').pop(),
    isCanceled: () => false
  }, options);

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    if (options.abortSignal) {
      on(options.abortSignal, 'abort', () => xhr.abort(), { once: true });

      if (options.abortSignal.id) {
        options.headers = Object.assign(options.headers || {}, { [HEADER_DISY_CANCEL_ID]: options.abortSignal.id });
      }
    }

    xhr.open(options.method, url, true);
    // required for CSRF check on server
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.responseType = options.responseType;
    Object.entries(options.headers).forEach(([ header, value ]) => {
      xhr.setRequestHeader(header, value);
    });

    xhr.onload = function () {
      if (xhr.status === 200) {
        const filename = options.filename ?? getFilename(xhr) ?? options.defaultFilename;
        LOGGER.info(`Downloading "${filename}" as a blob …`);
        if (navigator.msSaveBlob) { // IE, Edge with navigator.msSaveBlob
          navigator.msSaveBlob(xhr.response, filename);
        } else { // other browsers with download link
          // create the blob: URL
          const objectUrl = URL.createObjectURL(xhr.response);

          // create and attach a hidden anchor for the URL
          const a = document.createElement('a');
          a.href = objectUrl;
          if (filename) {
            a.download = filename;
          }
          a.hidden = true;
          a.target = '_blank';
          document.body.appendChild(a);

          // trigger the download
          if (!options.isCanceled()) {
            a.click();
          }

          // remove the anchor
          a.remove();

          // revoke the url to free memory
          URL.revokeObjectURL(objectUrl);
        }

        resolve(filename);
      } else {
        defaultXhrErrorHandler(xhr, reject);
      }
    };

    xhr.send();
  });
}

// https://stackoverflow.com/a/40940790
export function getFilename (xhr) {
  return xhr.getResponseHeader('Content-Disposition')
    ?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)?.[1]
    ?.replace(/['"]/g, '');
}

/**
 * Downloads the file from the given URL, without previously loading the whole file in a Blob.
 *
 * This allows the user to see the download progress via the browser's UI.
 *
 * It might be preferable for big files since the user can manage the download via the browser's UI.
 * It provides a more consistent and familiar UI.
 *
 * However, it allows much less control over the HTTP request than the other download function.
 *
 * @param {string} url - The URL of the file to download
 */
export function downloadImmediately (url) {
  // create and attach a hidden anchor for the URL
  const a = h('a', {
    href: url,
    download: '', // this attribute makes sure the content is downloaded and no navigation occurs to the URL
    hidden: true
  });
  document.body.appendChild(a);

  // trigger the download
  a.click();

  // remove the anchor
  a.remove();
}

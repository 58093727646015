const KNOWN_WINDOW_NAMES = [ '_self', '_blank', '_parent', '_top' ];

/**
 * Opens a URL in the given target window.
 *
 * This is intended to be a safe wrapper around `window.open()`:
 * - Typed options are used instead of a `windowFeatures` string.
 * - Access from the target window to the Cadenza window is allowed only for a target window with a
 *   custom name, because re-using a named window does not work otherwise.
 * - Does not return the target window, because `postMessage()` should be used to communicate with
 *   other windows.
 *
 * @param url - URL to open
 * @param [options]
 * @param [options.target='_blank'] - Name of the target window
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Window/open|Window.open() on MDN}
 */
export function openUrl (url: string, { target = '_blank' }: { target?: string } = {}) {
  // Generally we open new windows with the 'noopener' property set. This will
  // prevent the window.opener property from being set on the opened window.
  // However, this will also prevent the browser from re-using the tab should the
  // call to window.open be made again with the same target name, which is the
  // raison d'être for using non-listed window names.
  const noopener = KNOWN_WINDOW_NAMES.includes(target);
  // eslint-disable-next-line no-restricted-syntax
  setTimeout(() => window.open(url, target, noopener ? 'noopener' : ''));
}

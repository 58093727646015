import $ from 'jquery';

import { cadenzaUrl } from 'cadenza/utils/cadenza-url/cadenza-url';
import { cadenzaTopWindow } from 'cadenza/utils/iframe';
import { on } from 'cadenza/utils/event-util';

const CADENZA_SESSION_TIMEOUT_EVENT_NAME = 'cadenza-session-timeout';
const SESSION_POLLING_URL = '/session-polling';
const KEEP_ALIVE_PARAM = 'keepAlive';
const CADENZA_LOGIN_LOGOUT_PAGE = '/login';
const INVALID_SESSION_REDIRECT_URL = `${CADENZA_LOGIN_LOGOUT_PAGE}?loginStatus=invalidSession`;

export function setupSessionPoller (cadenzaSessionPollingInterval) {
  if (window === cadenzaTopWindow) {
    // The base bundle is loaded in the header of the page, that's why we need to wait for the loading of the page being finished.
    // We need the loading of the page being finished to check whether or not we're on the login/logout page because on this
    // page we don't want to setup the Session Poller.
    $(document).ready(function () {
      // Add listener on window to handle session timeouts occuring in the ajax-function in the future
      on(window, CADENZA_SESSION_TIMEOUT_EVENT_NAME, handleCadenzaSessionTimeOut);

      // Add session polling to the cadenzaTopWindow (and only there for the moment), if the current
      // page opened in the browser is not the login page
      const loginLogoutPageCurrentlyOpened = document.getElementById('loginForm') != null;
      if (!loginLogoutPageCurrentlyOpened && cadenzaSessionPollingInterval) {
        setInterval(() => {
          $.ajax(cadenzaUrl(SESSION_POLLING_URL))
            .fail(handleCadenzaSessionTimeOut);
        }, cadenzaSessionPollingInterval);
      }
    });
  }
}

function handleCadenzaSessionTimeOut () {
  window.Disy.clientSessionId = null;
  location.href = cadenzaUrl(INVALID_SESSION_REDIRECT_URL);
}

export function keepSessionAlive () {
  $.ajax(cadenzaUrl(`${SESSION_POLLING_URL}?${KEEP_ALIVE_PARAM}`));
}
